export const cards = [
  {
    title: 'Instalação de câmeras de segurança',
    description: 'Instalação de câmeras de segurança, alarmes, sensores de presença.'
  },
  {
    title: 'Instalação e manutenção de alarmes',
    description: 'Instalação e manutenção de alarmes de incêncio.'
  },
  {
    title: 'ART',
    description: `Anotações de Responsabilidade Técnica. Parecer conclusivo
      com anotações registradas junto aos órgãos competentes`,
  },
  {
    title: 'Laudos Técnicos',
    description: `Laudos técnicos de instalações de sistema de para-raios.
      Documento fundamental para assegurar que o sistema de proteção contra
      descarga atmosférica está em conformidade com as normas técnicas.`,
  },
  {
    title: 'Projetos elétricos',
    description: `Elaborações de projetos elétricos, SPDAs, iluminação de emergência,
      sistema de combate a incêndio.`,
  },

]
